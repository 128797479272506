.nav-pills{
  &.flex-column li > a{
      margin-bottom: 15px;
  }

  &:not(.nav-pills-icons):not(.nav-pills-just-icons) .nav-item .nav-link{
    border-radius: $btn-round-radius;
  }

  &.nav-pills-icons{
    .nav-item {
      i{
        font-size: 20px;
      }
    }
  }

  &.nav-pills-lg{
    .nav-item{
      .nav-link{
        padding: 11px 60px;
      }
    }
  }
}
