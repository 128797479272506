.modal {
    background-color: rgba(0,0,0,0.4);
}
.add_participants_popup_modal .modal-content {
    background-color: #1F2251;
    color: #fff;
    min-width: 540px;
}

.add_participants_popup_modal .modal-content .modal-header {
    border-bottom: 1px solid #313681;
    padding-bottom: 13px;
}

.add_participants_popup_modal .modal-content .modal-header .modal-title {
    font-size: 18px;
}

.add_participants_popup_modal .modal-content .modal-body {
    padding: 35px 30px 36px 30px;
}

.add_participants_popup_modal .modal-header,
.add_participants_popup_modal .modal-content .modal-header .modal-title,
.add_participants_popup_modal .modal-header button span {
    color: #fff;
}
.add_participants_popup_modal .form-control[disabled] {
    background-color: transparent;
}

.add_participants_popup_modal .modal-content .modal-footer {
    display: flex;
    justify-content: flex-start;
}

.add_participants_popup_modal .modal-content .modal-footer button {
    padding-left: 35px;
    padding-right: 35px;
    /* border-radius: 100px; */
}

.add_participants_popup_modal .labels {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-bottom: 5px;
    color: rgba(255, 255, 255, 0.6);
}