.tableLink {
    color: rgba(255, 255, 255, 0.7) !important;
    display: contents;
}
.custom-icon{
    font-size: 20px;
    display: block;
}
.logo-img {
    height: 60px;
    width: 250px;
  }
.landing-page .section:nth-of-type(3) {
    padding-top: 0px !important;
}
.footer .nav {
    display: block !important;
    float: right !important;
}
.footer .nav-link {
    display: flex !important;
    align-items: center !important;
    color: #fff !important;
}
.landingTable .table-pricing .btn {
    margin-bottom: 0px !important;
}
.landingIcons .icon{
    width: auto !important;
    height: auto !important;
}
.landingIcons .info .icon .bg-blob {
    width: 45px !important;
    margin-top: -25px !important;
    margin-left: -7px !important;
}
.landingIcons .info .info-title{
    color: #fff !important;
    font-weight: 500;
}
.landingIcons .info p{
    color: #aaa;
}
.landingIcons .icon-square-pin {
    color: #ab3fa5;
}
.tableLink {
    color: rgba(255, 255, 255, 0.7) !important;
    display: contents;
}

.green-text{
    color: #00f2c3 !important;
}

@media only screen and (min-width: 320px) and (max-width: 575px) {
.logo-img {
    height: auto;
    width: 200px;
}
.navbar .navbarContent .navbar-collapse {
    width: 90% !important;
    right: 0 !important;
    margin: 0px auto !important;
    background: #1f2251 !important;
    border-radius: 5px !important;
    padding: 15px !important;
    z-index: 9999 !important;
    margin-top: 15px !important;
}
.navbar .navbar-collapse .navbar-nav li {
    padding: 5px !important;
    text-align: left;
    width: 100%;
}
.navbar .navbar-nav .nav-item .nav-link{
    display: inline !important;
}

}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .logo-img {
        height: auto;
        width: 200px;
    }
    .navbar .navbarContent .navbar-collapse {
        width: 90% !important;
        right: 0 !important;
        margin: 0px auto !important;
        background: #1f2251 !important;
        border-radius: 5px !important;
        padding: 15px !important;
        z-index: 9999 !important;
        margin-top: 15px !important;
    }
    .navbar .navbar-collapse .navbar-nav li {
        padding: 5px !important;
        text-align: left;
        width: 100%;
    }
    .navbar .navbar-nav .nav-item .nav-link{
        display: inline !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .logo-img {
        height: auto;
        width: 150px;
    }
    .navbar .navbarContent .navbar-collapse {
        width: 96% !important;
        right: 0 !important;
        margin: 0px auto !important;
        background: #1f2251 !important;
        border-radius: 5px !important;
        padding: 15px !important;
        z-index: 9999 !important;
        margin-top: 15px !important;
    }
    .navbar .navbar-collapse .navbar-nav li {
        padding: 5px !important;
        text-align: left;
        width: 100%;
    }
    .navbar .navbar-nav .nav-item .nav-link{
        display: inline !important;
    }   
}