.card-blog {
  min-height: auto !important;
}
.table > tbody > tr:hover {
  cursor: pointer;
}
tr.load-div img {
  width: 50px;
}
.table-pricing tbody tr.load-div {
  background-color: transparent !important;
}
@media (max-width: 767px) {
  .center-img {
    text-align: center;
  }
  .center-img img {
    width: 65%;
  }
}
