.navbar {
  padding: $navbar-padding-x;
  width: $full-width;
  z-index: 1050;
  background: $primary;

  .photo {
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    vertical-align: middle;
    overflow: hidden;
    img {
      width: $full-width;
    }
  }

  .navbar-nav {
    .nav-item .nav-link {
      position: relative;
      color: inherit;
      line-height: 20px;
      display: flex;
      &:hover {
        color: $opacity-8;
      }
    }
  }

  .navbar-wrapper {
    display: flex;
    align-items: center;
  }

  .navbar-text {
    color: $white;
  }

  .btn {
    margin: 0 5px 0 10px;
  }
  p {
    margin: 0;
  }

  &.navbar-absolute {
    position: absolute;
    z-index: 1050;
  }

  &.navbar-transparent {
    background: $transparent-bg !important;
    padding-top: 25px;
  }

  &.bg-white {
    .navbar-nav {
      .search-bar.input-group {
        i {
          color: $black;
        }
      }

      .search-bar.input-group .form-control {
        background: rgba($black, 0.1);
        border-radius: 4px;
      }

      a.nav-link {
        color: $black !important;
        p {
          color: $black;
        }
      }
    }

    .navbar-text,
    .navbar-brand {
      color: $black;
    }

    .form-control {
      color: $black !important;
    }

    .form-control::placeholder {
      color: $dark-gray !important;
    }
  }

  &.bg-dark {
    background: $black !important;
  }

  &.bg-primary {
    background-color: $primary !important;
  }

  &.bg-warning {
    background-color: $warning !important;
  }

  &.bg-info {
    background-color: $info !important;
  }

  &.bg-success {
    background-color: darken($success, 10%) !important;
  }

  &.bg-danger {
    background-color: $danger !important;
  }

  .navbar-brand {
    position: relative;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    color: $white;
    text-transform: capitalize;
    font-size: $font-paragraph;
    span {
      font-weight: $font-weight-bold;
    }
  }

  .navbar-toggle button:focus,
  .navbar-toggler {
    outline: none;
  }
}

.navbar-minimize-fixed {
  position: fixed;
  margin-left: 40px;
  margin-top: 14px;
  transition: 0.3s ease;
  color: white;
  z-index: 20;
  opacity: 0;
  transition: 0.2s ease;
  button {
    i {
      font-size: 18px;
    }
  }
}

.notification {
  background: $danger;
  color: $white;
  border-radius: $border-radius-xl;
  height: 6px;
  width: 6px;
  position: absolute;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  top: 10px;
  right: 10px;
  border: 1px solid $danger;
}

.navbar-nav li {
  padding: 0 10px;
  a {
    color: $white;
  }
  i {
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
  }
}

.navbar-collapse-header {
  display: none;
}

@media screen and (max-width: 991px) {
  .navbar {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }

    .navbar-collapse {
      animation: show_navbar_collapse 0.2s ease forwards;

      .input-group {
        margin: 0;
        margin-top: 5px;
      }

      &.collapsing,
      &.collapse {
        animation: show_navbar_collapse 0.2s ease forwards;
      }

      &.collapsing-out {
        animation: hide_navbar_collapse 0.2s ease forwards;
      }

      .navbar-collapse-header {
        display: block;
        padding-bottom: 1rem;

        .collapse-brand {
          font-size: $font-size-xl;
          color: $white;
          a {
            font-weight: $font-weight-bold;
          }
        }

        .collapse-close {
          .navbar-toggler {
            color: $white;
            font-size: $font-size-xl;
          }
        }
      }

      .navbar-nav li {
        padding: 0px;
      }
    }

    .navbar-nav {
      .btn {
        margin-left: -3px;
        display: flex;

        i {
          margin-right: 12px;
        }

        span {
          margin: 0;
          text-transform: uppercase;
          font-weight: 300;

          &,
          &:hover,
          &:focus,
          &:active,
          &:active:focus {
            color: $black !important;
          }
        }
      }

      a.nav-link {
        i {
          opacity: 1;
          margin-right: 5px;
        }
        p {
          display: inline-block;
          margin-left: 7px;
          color: $white;
        }
      }

      .modal-search .modal-dialog {
        padding: 0 40px;
      }
      .dropdown {
        margin: 5px 0;

        .dropdown-menu {
          .dropdown-item {
            margin-top: 0;
            padding-left: 24px;
          }
        }
      }
    }

    .dropdown.show .dropdown-menu {
      display: block;
    }

    .dropdown .dropdown-menu {
      display: none;
      li a {
        color: $black;
      }
    }

    .dropdown.show .dropdown-menu,
    .dropdown .dropdown-menu {
      background-color: transparent;
      border: 0;
      transition: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      width: auto;
      margin: 0px 0.7rem;
      margin-top: 0px;

      &:before {
        display: none;
      }
    }

    .dropdown-menu .dropdown-item:focus,
    .dropdown-menu .dropdown-item:hover {
      color: $white;
    }

    &.bg-white .dropdown-menu .dropdown-item:focus,
    &.bg-white .dropdown-menu .dropdown-item:hover {
      color: $default;
    }

    button.navbar-toggler[data-target="#navigation"] {
      padding-top: 0;
    }

    .navbar-toggler-bar {
      display: block;
      position: relative;
      width: 22px;
      height: 1px;
      border-radius: 1px;
      background: $white;

      &.navbar-kebab {
        height: 4px;
        width: 4px;
        margin-bottom: 3px;
        border-radius: 50%;
      }

      & + .navbar-toggler-bar {
        margin-top: 7px;
      }

      & + .navbar-toggler-bar.navbar-kebab {
        margin-top: 0px;
      }

      &.bar2 {
        width: 17px;
        transition: width 0.2s linear;
      }
    }

    &.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
      background-color: $default;
    }

    .navbar-brand {
      margin-left: 20px;
      position: relative;
    }
  }

  .navbar-nav {
    .nav-link {
      i.fa,
      i.tim-icons {
        opacity: 0.5;
      }
    }
  }

  @include topbar-x-rotation();
  @include topbar-back-rotation();
  @include bottombar-x-rotation();
  @include bottombar-back-rotation();

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media screen and (min-width: 992px) {
  .navbar-collapse {
    background: none !important;
  }

  .navbar .navbar-toggle {
    display: none;
  }

  .navbar-nav {
    .nav-link {
      &.profile-photo {
        padding: 0;
        margin: 7px $padding-base-horizontal;
      }
    }
  }

  .navbar {
    .caret {
      position: absolute;
      left: 80%;
      top: 55%;
      margin-left: 0;
    }
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

@media screen and (max-width: 991px) {
  .navbar .navbar-translate {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between !important;
  }

  .navbar-collapse {
    padding: 1.4rem;
    height: auto !important;
  }

  .navbar-toggler.toggled + .navbar-collapse {
    display: block !important;
  }
}
@media screen and (max-width: 576px) {
  .navbar[class*="navbar-toggleable-"] .container {
    margin-left: 0;
    margin-right: 0;
  }
}

.navigation-example {
  .navbar {
    z-index: initial;
    .navbar-collapse {
      z-index: 1050;
    }
  }
}

@keyframes show_navbar_collapse {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hide_navbar_collapse {
  from {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0;
  }

  to {
    opacity: 0;
    transform: scale(0.95);
  }
}
