.rdtPickerup {
    .rdtPicker {
    top: inherit;
    bottom: 40px;
    &::before {
        top: inherit;
        bottom: -5px;
        transform: rotate(180deg);
    }
    &::after {
        opacity: 0;
    }
}
}
.required-mark {
    color: red;
}

.datetimepicker {
    font-size: 12px !important;
    // color: #fff !important;
    border: 1px solid #3f3f3f !important;
    width: 100% !important;
}
.MuiInput-underline.Mui-error:after{
    border: 0px !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0 !important;
}
.MuiInput-root {
    display: block !important;
    width: 90% !important;
}
.MuiFormControl-root{
    display: block !important;
}
.MuiInput-underline:before{
    border-bottom: 0 !important;
}
// .MuiSvgIcon-root{
//     display: inline-block !important;
//     position: absolute !important;
//     // top: 5px !important;
//     // right: -30px !important;
//     // fill: #fff !important;
// }
.MuiInputAdornment-root{
    display: inline-block !important;
    position: absolute !important;
    top: 5px !important;
    right: -30px !important;
    fill: #fff !important;
}

.MuiIconButton-label{
    display: block; 
}
// .MuiButtonBase-root {
//     display: block !important;
//     flex: unset !important;
//     position: unset !important;
// }
.MuiInputAdornment-positionEnd {
    display: block !important;
    margin-top: -10px;
}
.MuiInputBase-input, .MuiInputAdornment-positionEnd button{
    color: white !important;
}
.MuiButtonBase-root:focus {
    box-shadow: unset !important;
    outline: unset !important;
}
.Mui-error .MuiInputBase-input {
    border: 1px solid #f44336 !important;
}
.MuiInput-underline:after{
	left: unset !important;
}
.MuiTypography-root{
    color: rgb(54, 54, 54);
}