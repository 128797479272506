
.card-testimonial{
	@extend %card-profile;
    .card-body{
        padding-top: 25px;
    }

    .card-avatar{
        max-width: 100px;
        max-height: 100px;
        margin-top: -50px;
    }
    .card-footer{
        margin-bottom: 1.5rem;
    }
    .card-description{
        & + .card-title{
            margin-top: 30px;
        }
    }
    .icon{
        i{
          font-size: 32px;
        }

        &.icon-primary i{
            color: $primary;
        }

        &.icon-info i{
            color: $info;
        }

        &.icon-danger i{
            color: $danger;
        }

        &.icon-warning i{
            color: $warning;
        }

        &.icon-success i{
            color: $success;
        }
    }

	& .card-description + .card-title{
	    margin-top: 20px;
	}
}

@media screen and (max-width:991px){
	.testimonials-4 .positioned{
		position: relative;
	}
}
