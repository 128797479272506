
.login-page .card-reset .card-header img{
    height:335px;
    width:580px;
    }
    .login-page .card-login.card-reset .card-header .card-title{font-size:4.2em; padding-top: 7px;}
    .login-page .card-forgot .link.footer-link h6 {
    font-size: 10px;
    padding: 20px;
    
    }
    .login-page .card-reset .card-footer{
    padding-right:40px;
    padding-left:40px;
    margin: 0px;
    padding-top: 5px;
    padding-bottom: 0px;
    }
    .login-page .card-reset .card-body{
    padding-right:40px;
    padding-left:40px;
    }
    @media only screen and (max-width:1029px){
    .login-page .card-login.card-reset .card-header .card-title{font-size:3.8em;}
    }
    @media only screen and (max-width:767px){
    .login-page .card-reset .card-body{
    padding:15px 15px 5px;
    }
    .login-page .card-reset .card-footer{
    padding:5px 15px 0px;
    }
    .login-page .card-reset .card-header img{
    width: 100%!important;
    height: 330px;
    }
    .login-page .card-login.card-reset .card-header .card-title{font-size:3em;}
    }
    @media only screen and (max-width:460px){
    .login-page .card-reset .card-header img{
    width: 100%!important;
    height: 410px;
    }
    .login-page .card-login.card-reset .card-header .card-title{font-size:4em; padding-top: 10px;}
    }
    @media only screen and (max-width:411px){
    .login-page .card-reset .card-header img{
    width: 100%!important;
    height: 400px;
    }
    .login-page .card-login.card-reset .card-header .card-title{font-size:3.2em; line-height:1.3; padding-top: 20px;}
    }
    @media only screen and (max-width:320px){
    .login-page .card-login.card-reset .card-header .card-title{font-size:3em;}
    }