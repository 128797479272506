@media (min-width: 576px) {
    .modal-dialog {
        max-width: 700px !important;
    }

    .modal-dialog .row {
        display: block;
    }
}

.modal-dialog .col-md-6 {
    display: inline-block;
}

.close > span:not(.sr-only) {
    color: white !important;
}

.rbc-event .rbc-event-content {
    cursor: pointer;
}

.modal-content .modal-header {
    padding-bottom: 20px;
}
